<template>
  <div id="app">
    <b-navbar type="is-primary">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          dkmwm | Gewohnheitsgenerator
        </b-navbar-item>
      </template>
      <!--<template #start>
        <b-navbar-item href="#"> Home </b-navbar-item>
        <b-navbar-item href="#"> Documentation </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>-->
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary">Wie funktioniert das?</a>
            <a class="button is-primary">Kontakt</a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <section>
      <img src="@/assets/logo.png" class="logo" alt="e.fobi.de e. K." />
      <div class="columns">
        <div
          class="column behaviors is-half has-text-right pt-6 pr-6 pb-6 pl-0"
        >
          <div>
            <p class="heading">Ankermomente</p>
            <p class="title">Nachdem ich...</p>
            <div class="container my-6">
              <b-carousel-list
                v-model="behaviorsCarousel"
                :data="behaviors"
                :breakpoints="carouselBreakpoints"
                :items-to-show="itemsToShow"
              >
                <template #item="list">
                  <div
                    :class="{
                      card: true,
                      'has-background-secondary':
                        list.index === selectedBehavior,
                    }"
                    @click="selectedBehavior = list.index"
                  >
                    <div class="card-content">
                      <div class="content">
                        <p class="title is-6">{{ list.title }}</p>
                        <!--<p class="subtitle is-7">@johnsmith</p>-->
                      </div>
                    </div>
                  </div>
                </template>
              </b-carousel-list>
            </div>
            <div class="row">
              <b-button
                type="is-primary"
                icon-left="plus-box"
                @click="isAddBehaviorModalActive = true"
                >Anker hinzufügen</b-button
              >
            </div>
          </div>
        </div>
        <div
          class="column habits is-half has-text-left pt-6 pl-6 pb-6 pr-0 has-background-light"
        >
          <div>
            <p class="heading">Gewohnheiten</p>
            <p class="title">Werde ich...</p>
            <div class="container my-6">
              <b-carousel-list
                v-model="habitsCarousel"
                :data="habits"
                :breakpoints="carouselBreakpoints"
                :items-to-show="itemsToShow"
              >
                <template #item="list">
                  <div
                    class="card"
                    :class="{
                      card: true,
                      'has-background-secondary': list.index === selectedHabit,
                    }"
                    @click="selectedHabit = list.index"
                  >
                    <div class="card-content">
                      <div class="content">
                        <p class="title is-6">{{ list.title }}</p>
                        <!--<p class="subtitle is-7">@johnsmith</p>-->
                      </div>
                    </div>
                  </div>
                </template>
              </b-carousel-list>
            </div>
            <div class="row">
              <b-button
                type="is-primary"
                icon-left="plus-box"
                @click="isAddHabitModalActive = true"
                >Gewohnheit hinzufügen</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="has-text-centered m-6">
      <b-button
        size="is-large"
        type="is-secondary"
        icon-left="weight-lifter"
        @click="isRecipeModalActive = true"
        >Gewohnheit antrainieren</b-button
      >
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>dkmwm | Gewohnheitsgenerator</strong> in ein Service von
          <a href="https://jgthms.com">e-fobi.de e. K.</a>
        </p>
      </div>
    </footer>
    <b-modal v-model="isAddBehaviorModalActive" :width="640" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Anker hinzufügen</p>
          <button
            type="button"
            class="delete"
            @click="isAddBehaviorModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="Ankermoment">
            <b-input
              maxlength="200"
              type="textarea"
              v-model="customBehavior"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            type="button"
            class="button is-secondary"
            @click="
              behaviors.push({ title: customBehavior });
              selectedBehavior = behaviors.length - 1;
              behaviorsCarousel = behaviors.length - 1;
              isAddBehaviorModalActive = false;
            "
          >
            <!----><span>Hinzufügen</span
            ><!---->
          </button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="isAddHabitModalActive" :width="640" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Gewohnheit hinzufügen</p>
          <button
            type="button"
            class="delete"
            @click="isAddHabitModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="Gewohnheit">
            <b-input
              maxlength="200"
              type="textarea"
              v-model="customHabit"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            type="button"
            class="button is-secondary"
            @click="
              habits.push({ title: customHabit });
              selectedHabit = habits.length - 1;
              habitsCarousel = habits.length - 1;
              isAddHabitModalActive = false;
            "
          >
            <!----><span>Hinzufügen</span
            ><!---->
          </button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="isRecipeModalActive" :width="640" scroll="keep">
      <form ref="form" @submit.prevent="onSubmit">
        <vue-recaptcha ref="invisibleRecaptcha" sitekey="6LfpyEMaAAAAAIFxutwc3AY5Scab2rqz_Rij4kOk" @verify="onVerify" @expired="onExpired" size="invisible" :loadRecaptchaScript="true"></vue-recaptcha>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Verhaltensweise antrainieren</p>
            <button
              type="button"
              class="delete"
              @click="isRecipeModalActive = false"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="card mb-6">
              <div class="card-content">
                <p class="title recipe has-text-grey-light">
                  Nachdem ich<br /><span class="has-text-black">{{
                    behaviors[selectedBehavior].title
                  }}</span
                  >,<br />
                  werde ich<br /><span class="has-text-black">{{
                    habits[selectedHabit].title
                  }}</span
                  >.
                </p>
              </div>
            </div>
            <p class="mb-6">
              Ich helfe dir dabei dich bei deinem Ziel zu unterstützen. Per
              E-Mail erhälst du wertvolle Tipps, um dein Ziel nicht aus den
              Augen zu verlieren.
            </p>
            <b-field label="E-Mail-Adresse">
              <b-input type="email" v-model="email" required></b-input>
            </b-field>
            <b-field>
              <b-checkbox required
                >Hiermit bestätige ich, dass meine E-Mail-Adresse zum o.g. Zweck
                verwendet werden darf.</b-checkbox
              >
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-secondary" type="submit">
              <span>Absenden</span>
            </button>
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "App",
  components: { VueRecaptcha },
  data() {
    return {
      recaptcha: null,
      habitsCarousel: 0,
      behaviorsCarousel: 0,
      selectedBehavior: 1,
      selectedHabit: 1,
      isAddBehaviorModalActive: false,
      isAddHabitModalActive: false,
      isRecipeModalActive: false,
      customBehavior: "",
      customHabit: "",
      itemsToShow: 3.5,
      carouselBreakpoints: {
        481: {
          itemsToShow: 1,
        },
        769: {
          itemsToShow: 1.5,
        },
        1408: {
          itemsToShow: 2.5,
        },
      },
      behaviors: [
        {
          title: "Slide 1",
        },
        {
          title: "Slide 2",
        },
        {
          title: "Slide 3",
        },
        {
          title: "Slide 4",
        },
        {
          title: "Slide 5",
        },
        {
          title: "Slide 6",
        },
        {
          title: "Slide 7",
        },
        {
          title: "Slide 8",
        },
      ],
      habits: [
        {
          title: "Slide 1",
        },
        {
          title: "Slide 2",
        },
        {
          title: "Slide 3",
        },
        {
          title: "Slide 4",
        },
        {
          title: "Slide 5",
        },
        {
          title: "Slide 6",
        },
        {
          title: "Slide 7",
        },
        {
          title: "Slide 8",
        },
      ],
    };
  },
  methods: {
    onSubmit: function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify: function (response) {
      console.log('Verify: ' + response)
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    }
  }
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

$mobile: 481px;

// Set your colors
$primary: #686868;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$secondary: #F59641;
$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);
$secondary-invert: findColorInvert($secondary);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "secondary": (
      $secondary,
      $secondary-invert,
      $secondary-light,
      $secondary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

section {
  position: relative
}

img.logo {
  height: 10rem;
  top: 2rem;  
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-height: none !important;
  padding: 1rem 0
}

.columns .column {
  padding-top: 14rem !important
}

.carousel-list {
  box-shadow: none !important;
}
.carousel-list .carousel-slide {
  padding: 1rem;
}
.carousel-list .carousel-slide .card {
  height: 100%;
  min-height: 102px
}
.column.behaviors .carousel-list {
  border-right: 1px solid #eee;
}
.column.habits .carousel-list {
  border-left: 1px solid #e1e1e1;
}

@include until($tablet) {
  .column {
    color: #f00;
  }
  .column.behaviors {
    padding-left: 3rem !important;
  }
}
</style>
